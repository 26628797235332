export default {
  props: {},
  data: function () {
    let locales = Object.keys(this.getLocaleObjects());
    let countries = locales.map((item) => {
      return {
        countryCode: item.split("-").pop().toUpperCase(),
        locale: item,
      };
    });

    let countryData = this.getDynamicAssetUrl("core/data/country-list.json");

    return {
      locales: this.getLocaleObjects(),
      isLocaleDropdownOpened: false,
      countries: countryData,
    };
  },
  computed: {
    currentLocaleSlug() {
      return this.$store.getters["locale/slug"];
    },
    currentCountry() {
      let countrySlug = this.currentLocaleSlug.split("-").pop().toUpperCase();
      return this.countries[countrySlug];
    },
    countryIconUrl() {
      return this.currentCountry.image;
    },
    switchableLocales() {
      let result = {};
      for (const [slug, locale] of Object.entries(this.locales)) {
        if (slug !== this.currentLocaleSlug) {
          result[slug] = locale;
        }
      }
      return result;
    },
  },
  watch: {
    currentLocaleSlug(newVal) {
      this.closeLocaleDropdown();
    },
  },
  created() {
    let countryData = this.getDynamicAssetUrl("core/data/country-list.json");
  },
  methods: {
    setLocale(slug) {
      this.$store.commit("locale/slug", slug);
    },
    closeLocaleDropdown() {
      this.isLocaleDropdownOpened = false;
    },
    openLocaleDropdown() {
      this.isLocaleDropdownOpened = true;
    },
    getCountryIconUrl(slug) {
      let countrySlug = slug.split("-").pop().toUpperCase();
      return this.countries[countrySlug].image;
    },
  },
};
